import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GeneratePdf from "../../../Utils/GeneratePdf";
import LetterHead from "../../../components/LetterHead";

const IncomeStatement = () => {
	const [FromDate, setFromDate] = useState(
		new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			1
		).toLocaleDateString("en-CA")
	);
	const [ToDate, setToDate] = useState(
		new Date(
			new Date().getFullYear(),
			new Date().getMonth() + 1,
			0
		).toLocaleDateString("en-CA")
	);

	const [Summary, setSummary] = useState("custom");
	const [FirstDayUnix, setFirstDayUnix] = useState(
		new Date(FromDate).getTime()
	);
	const [LastDayUnix, setLastDayUnix] = useState(
		new Date(ToDate).setHours(23, 59, 59, 999)
	);

	// Recalculate timestamps when FromDate or ToDate changes
	useEffect(() => {
		setFirstDayUnix(new Date(FromDate).getTime());
		setLastDayUnix(new Date(ToDate).setHours(23, 59, 59, 999));
	}, [FromDate, ToDate]);

	const Payments = useSelector((state) => state.payment.payments);
	const Invoices = useSelector((state) => state.invoice.invoices);

	const Expenses = useSelector((state) => state.expense.expenses).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			item.linker >= FirstDayUnix &&
			item.linker <= LastDayUnix
	);

	const PayrollCats = useSelector(
		(state) => state.payrollCat.payrollCats
	).filter((item) => parseInt(item.deleted) === 0);

	const PayrollItems = useSelector(
		(state) => state.payrollItem.payrollItems
	).filter((item) => parseInt(item.deleted) === 0);

	const PayrollEntries = useSelector(
		(state) => state.payrollEntry.payrollEntries
	).filter(
		(item) =>
			parseInt(item.deleted) === 0 &&
			item.month >= new Date(FirstDayUnix).toISOString().slice(0, 7) &&
			item.month <= new Date(LastDayUnix).toISOString().slice(0, 7)
	);

	const FilteredPayrollEntries = PayrollEntries.filter((item) =>
		PayrollItems.some(
			(payrollItem) =>
				parseInt(payrollItem.linker) === parseInt(item.itemLinker) &&
				PayrollCats.some(
					(payrollCat) =>
						payrollCat.linker === payrollItem.catLinker &&
						["Income", "Allowance"].includes(payrollCat.type)
				)
		)
	);

	const PharmacyEntries = useSelector((state) => state.entry.entries).filter(
		(entry) => parseInt(entry.deleted) === 0
	);

	const StoreEntries = useSelector(
		(state) => state.storeEntry.storeEntries
	).filter((entry) => parseInt(entry.deleted) === 0);

	// Filter Pharmacy transactions within the date range
	const filteredData = PharmacyEntries.filter(
		(item) => item.linker >= FirstDayUnix && item.linker <= LastDayUnix
	);

	// Filter Invoice transactions within the date range
	const filteredInvoices = Invoices.filter(
		(item) =>
			item.linker >= FirstDayUnix &&
			item.linker <= LastDayUnix &&
			parseInt(item.deleted) === 0
	);

	// Filter Payment transactions within the date range
	const filteredPayments = Payments.filter(
		(item) =>
			item.linker >= FirstDayUnix &&
			item.linker <= LastDayUnix &&
			parseInt(item.deleted) === 0
	);

	const GetCurrentWeek = () => {
		const currentDate = new Date();
		const dayOfWeek = currentDate.getDay() || 7; // Set Sunday as 7 for ISO week
		currentDate.setDate(currentDate.getDate() + 4 - dayOfWeek); // Shift date to Thursday in the current week
		const yearStart = new Date(currentDate.getFullYear(), 0, 1);
		const weekNumber = Math.ceil(
			((currentDate - yearStart) / 86400000 + 1) / 7
		);

		return `${currentDate.getFullYear()}-W${weekNumber
			.toString()
			.padStart(2, "0")}`;
	};

	const GetCurrentMonth = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
		return `${year}-${month}`;
	};

	const GetWeekTimestamps = (weekString) => {
		const [year, week] = weekString.split("-W").map(Number);

		// Get the first day of the year
		const firstDayOfYear = new Date(year, 0, 1);

		// Calculate the day of the week for the first day of the year (0 = Sunday, 6 = Saturday)
		const dayOfWeek = firstDayOfYear.getDay() || 7; // Adjust so Sunday is 7

		// Get the first Monday of the year
		const dayOffset = dayOfWeek <= 4 ? dayOfWeek - 1 : dayOfWeek - 8;
		const firstMonday = new Date(
			firstDayOfYear.setDate(firstDayOfYear.getDate() - dayOffset)
		);

		// Calculate the start of the given week
		const weekStart = new Date(
			firstMonday.setDate(firstMonday.getDate() + (week - 1) * 7)
		);

		// Calculate the end of the week (Sunday)
		const weekEnd = new Date(weekStart);
		weekEnd.setDate(weekStart.getDate() + 6); // Move to Sunday

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = weekStart.getTime();
		const endTimestamp = weekEnd.getTime() + 24 * 60 * 60 * 1000 - 1; // End of the day on Sunday
		setFirstDayUnix(startTimestamp);
		setLastDayUnix(endTimestamp);
		return { startTimestamp, endTimestamp };
	};

	const GetMonthTimestamps = (monthString) => {
		const [year, month] = monthString.split("-").map(Number);

		// Get the first day of the month (month is 0-indexed)
		const firstDayOfMonth = new Date(year, month - 1, 1);

		// Get the first day of the next month
		const firstDayOfNextMonth = new Date(year, month, 1);

		// The end of the month is the last millisecond of the previous day of the next month
		const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfMonth.getTime(); // Start of the month
		const endTimestamp = lastDayOfMonth.getTime(); // End of the month (last millisecond)
		setFirstDayUnix(startTimestamp);
		setLastDayUnix(endTimestamp);
		return { startTimestamp, endTimestamp };
	};

	const GetYearTimestamps = (yearString) => {
		const year = Number(yearString);

		// Get the first day of the year (January 1st, 00:00)
		const firstDayOfYear = new Date(year, 0, 1);

		// Get the first day of the next year
		const firstDayOfNextYear = new Date(year + 1, 0, 1);

		// The end of the year is the last millisecond of December 31st
		const lastDayOfYear = new Date(firstDayOfNextYear.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfYear.getTime(); // Start of the year
		const endTimestamp = lastDayOfYear.getTime(); // End of the year (last millisecond)

		setFirstDayUnix(startTimestamp);
		setLastDayUnix(endTimestamp);

		return { startTimestamp, endTimestamp };
	};

	const calculatePharmacyFIFOCOGS = (transactions, startDate, endDate) => {
		// Sort transactions by linker (timestamp) in ascending order
		transactions.sort((a, b) => Number(a.linker) - Number(b.linker));

		const inventory = {}; // Current inventory for each productLinker
		let cogs = 0; // Cost of Goods Sold

		const initialInventory = {}; // Snapshot of inventory at startDate
		const finalInventory = {}; // Snapshot of inventory at endDate

		transactions.forEach((transaction) => {
			const {
				productLinker,
				quantity: qtyStr,
				amount: amtStr,
				type,
				linker,
			} = transaction;

			// Convert quantity and amount to numbers
			const quantity = Number(qtyStr);
			const amount = Number(amtStr);
			const timestamp = Number(linker);

			// Initialize inventory stack for the product if not present
			if (!inventory[productLinker]) {
				inventory[productLinker] = [];
			}

			// Helper function to add a batch to inventory
			const addToInventory = (qty, unitCost) => {
				inventory[productLinker].push({ quantity: qty, unitCost });
			};

			// Helper function to remove quantity from inventory using FIFO
			const removeFromInventory = (qty) => {
				let remainingQty = qty;
				let totalCost = 0;

				while (remainingQty > 0 && inventory[productLinker].length > 0) {
					const batch = inventory[productLinker][0];

					if (batch.quantity <= remainingQty) {
						totalCost += batch.quantity * batch.unitCost;
						remainingQty -= batch.quantity;
						inventory[productLinker].shift(); // Remove the batch
					} else {
						totalCost += remainingQty * batch.unitCost;
						batch.quantity -= remainingQty;
						remainingQty = 0;
					}
				}

				return totalCost;
			};

			// Helper function to remove quantity from inventory using LIFO (for return-purchase and damage)
			const removeFromInventoryLIFO = (qty) => {
				let remainingQty = qty;

				while (remainingQty > 0 && inventory[productLinker].length > 0) {
					const batch =
						inventory[productLinker][inventory[productLinker].length - 1];

					if (batch.quantity <= remainingQty) {
						remainingQty -= batch.quantity;
						inventory[productLinker].pop(); // Remove the batch
					} else {
						batch.quantity -= remainingQty;
						remainingQty = 0;
					}
				}
			};

			// Process the transaction based on its type
			switch (type) {
				case "purchase":
					addToInventory(quantity, amount / quantity);
					break;

				case "sale":
					// If within COGS calculation period, calculate COGS
					if (timestamp >= startDate && timestamp <= endDate) {
						const cost = removeFromInventory(quantity);
						cogs += cost;
					} else {
						// If outside the COGS period, just remove from inventory
						removeFromInventory(quantity);
					}
					break;

				case "return-purchase":
					// Remove returned purchased items using LIFO
					removeFromInventoryLIFO(quantity);
					break;

				case "return-sale":
					// Add returned sold items back to inventory (assuming returned at original sale price)
					addToInventory(quantity, amount / quantity);
					break;

				case "damaged":
					// Remove damaged goods using FIFO (as per standard inventory practices)
					removeFromInventory(quantity);
					break;

				default:
					console.warn(`Unknown transaction type: ${type}`);
					break;
			}

			// Snapshot initial inventory at the startDate
			if (timestamp < startDate && !initialInventory[productLinker]) {
				initialInventory[productLinker] = inventory[productLinker].map(
					(batch) => ({ ...batch })
				);
			}

			// Snapshot final inventory at the endDate
			if (timestamp <= endDate) {
				finalInventory[productLinker] = inventory[productLinker].map(
					(batch) => ({
						...batch,
					})
				);
			}
		});

		return {
			cogs,
			initialInventory,
			finalInventory,
		};
	};

	const calculateStoreFIFOCOGS = (transactions, startDate, endDate) => {
		// Sort transactions by linker (timestamp) in ascending order
		transactions.sort((a, b) => Number(a.linker) - Number(b.linker));

		const inventory = {}; // Current inventory for each itemLinker
		let cogs = 0; // Cost of Goods Sold

		const initialInventory = {}; // Snapshot of inventory at startDate
		const finalInventory = {}; // Snapshot of inventory at endDate

		transactions.forEach((transaction) => {
			const {
				itemLinker,
				quantity: qtyStr,
				amount: amtStr,
				type,
				linker,
			} = transaction;

			// Convert quantity and amount to numbers
			const quantity = Number(qtyStr);
			const amount = Number(amtStr);
			const timestamp = Number(linker);

			// Initialize inventory stack for the product if not present
			if (!inventory[itemLinker]) {
				inventory[itemLinker] = [];
			}

			// Helper function to add a batch to inventory
			const addToInventory = (qty, unitCost) => {
				inventory[itemLinker].push({ quantity: qty, unitCost });
			};

			// Helper function to remove quantity from inventory using FIFO
			const removeFromInventory = (qty) => {
				let remainingQty = qty;
				let totalCost = 0;

				while (remainingQty > 0 && inventory[itemLinker].length > 0) {
					const batch = inventory[itemLinker][0];

					if (batch.quantity <= remainingQty) {
						totalCost += batch.quantity * batch.unitCost;
						remainingQty -= batch.quantity;
						inventory[itemLinker].shift(); // Remove the batch
					} else {
						totalCost += remainingQty * batch.unitCost;
						batch.quantity -= remainingQty;
						remainingQty = 0;
					}
				}

				return totalCost;
			};

			// Helper function to remove quantity from inventory using LIFO (for return-received and damage)
			const removeFromInventoryLIFO = (qty) => {
				let remainingQty = qty;

				while (remainingQty > 0 && inventory[itemLinker].length > 0) {
					const batch = inventory[itemLinker][inventory[itemLinker].length - 1];

					if (batch.quantity <= remainingQty) {
						remainingQty -= batch.quantity;
						inventory[itemLinker].pop(); // Remove the batch
					} else {
						batch.quantity -= remainingQty;
						remainingQty = 0;
					}
				}
			};

			// Process the transaction based on its type
			switch (type) {
				case "received":
					addToInventory(quantity, amount / quantity);
					break;

				case "issued":
					// If within COGS calculation period, calculate COGS
					if (timestamp >= startDate && timestamp <= endDate) {
						const cost = removeFromInventory(quantity);
						cogs += cost;
					} else {
						// If outside the COGS period, just remove from inventory
						removeFromInventory(quantity);
					}
					break;

				case "return-received":
					// Remove returned received items using LIFO
					removeFromInventoryLIFO(quantity);
					break;

				case "return-issued":
					// Add returned sold items back to inventory (assuming returned at original sale price)
					addToInventory(quantity, amount / quantity);
					break;

				case "damaged":
					// Remove damaged goods using FIFO (as per standard inventory practices)
					removeFromInventory(quantity);
					break;

				default:
					console.warn(`Unknown transaction type: ${type}`);
					break;
			}

			// Snapshot initial inventory at the startDate
			if (timestamp < startDate && !initialInventory[itemLinker]) {
				initialInventory[itemLinker] = inventory[itemLinker].map((batch) => ({
					...batch,
				}));
			}

			// Snapshot final inventory at the endDate
			if (timestamp <= endDate) {
				finalInventory[itemLinker] = inventory[itemLinker].map((batch) => ({
					...batch,
				}));
			}
		});

		return {
			cogs,
			initialInventory,
			finalInventory,
		};
	};

	const PharmacyResult = calculatePharmacyFIFOCOGS(
		PharmacyEntries,
		FirstDayUnix,
		LastDayUnix
	);

	const StoreResult = calculateStoreFIFOCOGS(
		StoreEntries,
		FirstDayUnix,
		LastDayUnix
	);
	const [Week, setWeek] = useState(GetCurrentWeek());
	const [Month, setMonth] = useState(GetCurrentMonth());
	const CurrentYear = new Date().getFullYear();
	const [Year, setYear] = useState(CurrentYear);
	const Years = Array.from({ length: 100 }, (v, i) => CurrentYear - i);

	return (
		<div>
			<div className="row justify-content-center">
				<div className="col-md-6 col-lg-5 col-xl-4 card m-1" id="Profit&Loss">
					<LetterHead></LetterHead>
					<p className="text-center">
						<strong>Profit & Loss </strong>
					</p>
					<div className="d-flex justify-content-around card-header">
						<div>
							<strong>Type : </strong>
							<select
								onChange={(e) => {
									setSummary(e.target.value);
									return e.target.value === "weekly"
										? GetWeekTimestamps(Week)
										: e.target.value === "monthly"
										? GetMonthTimestamps(Month)
										: e.target.value === "annual"
										? GetYearTimestamps(Year)
										: (setFirstDayUnix(new Date(FromDate).getTime()),
										  setLastDayUnix(new Date(ToDate).getTime()));
								}}
								value={Summary}
								className="rounded "
							>
								<option value={"custom"}>Custom Sum</option>
								<option value={"weekly"}>Weekly Sum</option>
								<option value={"monthly"}>Monthly Sum</option>
								<option value={"annual"}>Annual Sum</option>
							</select>
						</div>
						{Summary === "weekly" ? (
							<div>
								<input
									type="week"
									value={Week}
									onChange={(e) => {
										setWeek(e.target.value);
										GetWeekTimestamps(e.target.value);
									}}
									onClick={(e) => e.target.showPicker()}
									className="rounded form-control"
								/>
							</div>
						) : Summary === "monthly" ? (
							<div>
								<input
									type="month"
									value={Month}
									onClick={(e) => e.target.showPicker()}
									onChange={(e) => {
										setMonth(e.target.value);
										GetMonthTimestamps(e.target.value);
									}}
									className="rounded form-control"
								/>
							</div>
						) : Summary === "annual" ? (
							<div>
								<select
									className="rounded  form-control p-1"
									value={Year}
									onChange={(e) => {
										setYear(e.target.value);
										GetYearTimestamps(e.target.value);
									}}
								>
									{Years.map((year) => (
										<option key={year} value={year}>
											{year}
										</option>
									))}
								</select>
							</div>
						) : (
							<>
								<div>
									<strong>From : </strong>
									<input
										onClick={(e) => e.target.showPicker()}
										type="date"
										className="rounded"
										value={FromDate}
										onChange={(e) => setFromDate(e.target.value)}
									/>
								</div>
								<div>
									<strong>To : </strong>{" "}
									<input
										onClick={(e) => e.target.showPicker()}
										type="date"
										className="rounded"
										value={ToDate}
										onChange={(e) => setToDate(e.target.value)}
										min={FromDate}
									/>
								</div>
							</>
						)}
					</div>
					<div className="card-body">
						<table className="table table-sm table-striped">
							<tr>
								<th>Services Offered {`{Invoices}`}</th>
								<td>{filteredInvoices.reduce((a, b) => +a + +b.amount, 0)}</td>
							</tr>
							<tr>
								<th>Pharmacy Sales</th>

								<td>
									{filteredData
										.filter((entry) => entry.type === "sale")
										.reduce((a, b) => +a + +b.amount, 0) -
										filteredData
											.filter((entry) => entry.type === "return-sale")
											.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th>Cost Of Pharmacy Goods Sold</th>
								<td>-{PharmacyResult.cogs}</td>
							</tr>
							<tr>
								<th>Cost Of Materials Used{`{Store}`}</th>
								<td>-{StoreResult.cogs}</td>
							</tr>
							<tr>
								<th className="text-decoration-underline">Gross Profit</th>
								<td className="text-decoration-underline">
									{filteredData
										.filter((entry) => entry.type === "sale")
										.reduce((a, b) => +a + +b.amount, 0) +
										filteredInvoices.reduce((a, b) => +a + +b.amount, 0) -
										PharmacyResult.cogs -
										StoreResult.cogs}
								</td>
							</tr>
							<tr>
								<th>Salaries</th>
								<td>
									-{FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th>Expenses</th>
								<td>-{Expenses.reduce((a, b) => +a + +b.amount, 0)}</td>
							</tr>
							<tr>
								<th className="text-decoration-underline">
									Expected Net Profit Before Tax
								</th>
								<td className="text-decoration-underline">
									{filteredData
										.filter((entry) => entry.type === "sale")
										.reduce((a, b) => +a + +b.amount, 0) +
										filteredInvoices.reduce((a, b) => +a + +b.amount, 0) -
										PharmacyResult.cogs -
										StoreResult.cogs -
										FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0) -
										Expenses.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th>Unpaid Invoices {`{Debts}`}</th>
								<td>
									{filteredInvoices.reduce((a, b) => +a + +b.amount, 0) -
										filteredPayments.reduce((a, b) => +a + +b.amount, 0) +
										filteredData
											.filter((entry) => entry.type === "sale")
											.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
							<tr>
								<th className="text-decoration-underline">
									Realized Net Profit Before Tax
								</th>
								<td className="text-decoration-underline">
									{filteredPayments.reduce((a, b) => +a + +b.amount, 0) -
										PharmacyResult.cogs -
										StoreResult.cogs -
										FilteredPayrollEntries.reduce((a, b) => +a + +b.amount, 0) -
										Expenses.reduce((a, b) => +a + +b.amount, 0)}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<GeneratePdf
				id="Profit&Loss"
				name={`Profi&Loss-${FromDate}-to-${ToDate}`}
			></GeneratePdf>
		</div>
	);
};

export default IncomeStatement;
